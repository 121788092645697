import axios from "axios";

export class Utils {

    truncateString(str: string, num: number) {
        if (str.length > num) {
          return str.slice(0, num) + "...";
        } else {
          return str;
        }
    }
      
    displayNanos(number: number) {
        return new Intl.NumberFormat().format(number / 1e9);
    }  

    displayFiat(number: number) {
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(number / 100);
    }  

    publicKeyImageUrl(publicKey: string) {
        return 'https://diamondapp.com/api/v0/get-single-profile-picture/'+ publicKey + 
            '?fallback=https://diamondapp.com/assets/img/default_profile_pic.png';
    }

    async getNftStats(publicKey: string) {
        const body = {};

        let error = null;
        let data = null;
        try {
            const response = await axios.post('deso/collection/stats/'+publicKey, body);
            if (response.status == 200) {
                data = response.data.value;
            } else {
                throw new Error(`${response.status}`);
            }
        } catch (err) {
            error = err;
        }

        return {
            data: data,
            error: error
        };
    }

}

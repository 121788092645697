
import { IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonPage, IonRouterOutlet } from '@ionic/vue';
import { list, mailUnreadOutline, notificationsOutline, settings, colorPalette, apps } from 'ionicons/icons';

export default {
  name: 'Tabs',
  components: { IonLabel, IonTabs, IonTabBar, IonTabButton, IonIcon, IonPage, IonRouterOutlet },
  setup() {
    return {
      list, 
      settings, 
      mailUnreadOutline,
      notificationsOutline,
      colorPalette,
      apps
    }
  }
}

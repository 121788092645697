<template>
  <ion-card v-if="stats" class="no-padding">
    <ion-list>
      <ion-item>
        <ion-label>Total Volume</ion-label>
        <ion-note slot="end" color="contrast">
          <div class="flex items-center" style="">
            <img class="deso-xs" src="/assets/images/deso.svg" />
            <span>
              {{ utils.displayNanos(stats.NFTTotalAcceptedBidVolumeNanos) }}
            </span>
          </div>
        </ion-note>
      </ion-item>

      <ion-item>
        <ion-label>All time high sale</ion-label>
        <ion-note slot="end" color="contrast">
          <div class="flex items-center" style="" v-if="stats.NFTHighestAcceptedBidRecord">
            <img class="deso-xs" src="/assets/images/deso.svg" />
            <span>
              {{ utils.displayNanos(stats.NFTHighestAcceptedBidRecord.BidAmountNanos) }}
            </span>
          </div>
          <div v-else>
            No sales
          </div>
        </ion-note>
      </ion-item>

      <ion-item>
        <ion-label>NFTs available</ion-label>
        <ion-note slot="end" color="contrast">
          {{ stats.NFTAvailableCount }}
        </ion-note>
      </ion-item>
      <ion-item lines="none">
        <ion-label>Owners</ion-label>
        <ion-note slot="end" color="contrast">
            {{ stats.NFTDistinctOwnerCount }}
        </ion-note>
      </ion-item>
    </ion-list>
  </ion-card>
</template>

<script>

import { ref, defineComponent } from "vue";
import { Utils } from "../services/utils";

import {
    IonCard,
    IonList,
    IonItem,
    IonLabel,
    IonNote,
} from "@ionic/vue";

export default defineComponent({
  components: {
    IonCard,
    IonList,
    IonItem,
    IonLabel,
    IonNote,

    // ApiService,
  },
    
  props: {
    collection: null
  },

setup(props) {
    const utils = new Utils;
    const stats = ref(null);
    
    function loadStats() {
      utils.getNftStats(props.collection.publicKey).then(function(response) {
        stats.value = response.data;
      });
    }
    loadStats();

    return {
      stats: stats,
      
      utils: utils,
    }
  }
});
</script>
<template>
<ion-col
    key="{nft.id}"
    sizeXs="6"
    sizeSm="6"
    sizeMd="4"
    sizeLg="3"
    sizeXl="2"
    >
    <ion-card class="no-padding">
        <div v-if="showHeader" class="nft-grid-item_card-header" @click="openCollectionModal">
            <ion-chip class="" slot="start">
                <ion-avatar>
                    <img :title="nft.collection.username" :src="utils.publicKeyImageUrl(nft.collection.publicKey)">
                </ion-avatar>
                <ion-label>
                    {{ nft.collection.username }}
                </ion-label>
            </ion-chip>

            <ion-avatar class="tiny-avatar" slot="end">
                <img :title="nft.account.username" :src="utils.publicKeyImageUrl(nft.account.publicKey)">
            </ion-avatar>
        </div>
        <div @click="openNftModal">
            <ion-img v-if="haveImage" :src="nft.imageUrl"/>
            <div v-else>
                <div v-if="haveVideo" class="bg-gray-200 h-52 flex items-center justify-center">
                    <ion-icon color="dark" size="large" :icon="playOutline"></ion-icon>
                </div>
                <p>
                    {{ utils.truncateString(nft.body, 49) }}
                </p>
            </div>
        </div>
        <Ion-card-header class="no-padding">
            <ion-card-title class="nft-grid-item_title" v-if="haveImage">
                {{ utils.truncateString(nft.body, 49) }}
            </ion-card-title>
        </ion-card-header>
        <div>
            <div class="nft-grid-item_card-footer">
                <ion-label class="flex items-center">
                    <img class="deso-xs" src="/assets/images/deso.svg" />
                    <span>
                        {{ utils.displayNanos(nft.paidNanos) }}
                    <!-- {{ (nft.paidNanos / 1e9).toLocaleString(undefined, { minimumFractionDigits: 2 })}} -->
                    </span>
                </ion-Label>
                <ion-label>
                    #{{ nft.serialNumber}}
                </ion-Label>
            </div>
        </div>
    </ion-card>
    </ion-col>
</template>

<script>
import { computed, defineComponent } from 'vue';
import { Utils } from "../services/utils";
import CollectionHoldingModal from '../modals/CollectionHoldingModal.vue';
import NftDetailModal from '../modals/NftDetailModal.vue';

import { 
  playOutline,
} from 'ionicons/icons';

import {
    IonImg, IonAvatar, 
    // IonItem,
    IonCol, IonCard, 
    // IonCardContent, 
    IonCardHeader, 
    IonChip,
    IonCardTitle, 
    IonLabel,

    modalController,
} from '@ionic/vue';

export default defineComponent({
    components: { 
        IonCol, IonCard, 
        IonCardHeader, 
        IonChip,
        IonCardTitle, 
        IonImg, IonAvatar, IonLabel
    },
    
    props: {
        nft: null,
        showHeader: {
            type: Boolean,
            default: true
        }
    },

    methods: {
        async openCollectionModal() {
            const modal = await modalController
                .create({
                component: CollectionHoldingModal,
                cssClass: 'CollectionHoldingModal',
                componentProps: {
                    title: 'Collection Holdings',
                    collection: this.nft.collection
                },
                })
            await modal.present();
            await modal.onDidDismiss();
        },

        async openNftModal() {
            const nftModal = await modalController
                .create({
                component: NftDetailModal,
                cssClass: 'NftDetailModal',
                componentProps: {
                    title: 'NFT Details',
                    nft: this.nft
                },
                })
            await nftModal.present();
            await nftModal.onDidDismiss();
        },
    },

    setup(props) {
        const utils = new Utils();

        const haveImage = computed(function () {
            return props.nft.imageUrl;
        });

        const haveVideo = computed(function () {
            return props.nft.videoUrl;
        });

        return {
            utils: utils,
            haveImage : haveImage,
            haveVideo : haveVideo,
            playOutline,
        }
    }
});
</script>

<template>
  <ion-grid>
    <ion-row>
      <nft-grid-item v-for="item in nfts" :nft="item" :showHeader="showHeader" :key="item.id" />
    </ion-row>
  </ion-grid>
</template>

<script>
import { defineComponent } from "vue";
import { IonGrid, IonRow } from '@ionic/vue';
import NftGridItem from "@/components/NftGridItem.vue";

export default defineComponent({
    components: { IonGrid, IonRow, NftGridItem},

    props: {
        nfts: null,
        showHeader: {
          type: Boolean,
          default: false
        }
    },
    
});
</script>

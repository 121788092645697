
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import { onUnmounted, defineComponent, onMounted } from 'vue';
import { useStore } from "vuex";
import { ApiService } from './services/api';

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet
  },

  setup() {
    const store = useStore();
    let timer = 0;

    function refresh() {
      store.dispatch("loadExchangeRates");
    }

    function refreshExchangeRates () {
      refresh();      
    }


    onMounted(() => {
      ApiService.init(process.env.VUE_APP_API_BASE_URL, process.env.VUE_APP_API_TOKEN);

      refreshExchangeRates();
      timer = setInterval(refreshExchangeRates, 60 * 1000);
    })

    onUnmounted(() => {
      clearInterval(timer);
    })

  },
});

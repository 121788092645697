<template>
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="end">
        <ion-button @click="closeModal">Close</ion-button>
      </ion-buttons>

      <ion-title>{{ title }}</ion-title>

    </ion-toolbar>
  </ion-header>

  <ion-content>
      <div class="nft-grid-item_card-header">
            <ion-chip class="" slot="start">
                <ion-avatar>
                    <img :title="nft.collection.username" :src="utils.publicKeyImageUrl(nft.collection.publicKey)">
                </ion-avatar>
                <ion-label>
                    {{ nft.collection.username }}
                </ion-label>
            </ion-chip>

            <ion-avatar class="tiny-avatar" slot="end">
                <img :title="nft.account.username" :src="utils.publicKeyImageUrl(nft.account.publicKey)">
            </ion-avatar>


        </div>

      <div>
            <ion-img v-if="haveImage" :src="nft.imageUrl"/>

            <iframe :src="nft.videoUrl"  v-if="haveVideo" frameborder="0" width="100%" height="300"></iframe>

      </div>

        <div class="">

      <div class="">
        <div class="grid grid-cols-2">
          <div v-if="nft.isForSale" class="ion-padding">
            For sale
          </div>
          <div v-else class="ion-padding">
            Not for sale
          </div>

          <div class="ion-padding text-right">
            <a target="_blank" :href="getUrl">Link</a>
          </div>
        </div>
      </div>

            <ion-grid class="border-t border-b rounded px-1">
              <ion-row class="p-0 m-0">
                <ion-col sizeXs="6" class="border-b flex items-center">
                  <ion-icon src="/assets/icon/repost.svg"></ion-icon> 
                  <span class="ml-1">
                    {{ nft.quoteRepostCount + nft.repostCount }} reposts
                  </span>
                </ion-col>
                <ion-col sizeXs="6" class="border-b border-l flex items-center">
                  <ion-icon src="/assets/icon/heart.svg"></ion-icon>
                  <span class="ml-1">
                    {{ nft.likeCount }} likes
                  </span>
                </ion-col>
                <ion-col sizeXs="6" class="flex items-center">
                  <ion-icon src="/assets/icon/diamond.svg"></ion-icon>
                  <span class="ml-1">
                  {{ nft.diamondCount }} diamonds
                  </span>
                </ion-col>
                <ion-col sizeXs="6" class="border-l flex items-center">
                  <ion-icon src="/assets/icon/comment.svg"></ion-icon>
                  <span class="ml-1">
                  {{ nft.commentCount }} comments
                  </span>
                </ion-col>
              </ion-row>
            </ion-grid>
        </div>

            <div class="border-b p-3 flex items-center">
                <ion-label class="flex items-center">
                  Last price &nbsp; <img class="deso-xs" src="/assets/images/deso.svg" />
                    <span>
                        {{ utils.displayNanos(nft.paidNanos) }}
                    <!-- {{ (nft.paidNanos / 1e9).toLocaleString(undefined, { minimumFractionDigits: 2 })}} -->
                    </span>
                </ion-Label>
                <ion-label class="ml-auto">
                    #{{ nft.serialNumber}}
                </ion-Label>
            </div>

      <div class="ion-padding">
        <ion-text color="dark">
          {{ nft.body }}
        </ion-text>
      </div>

      <div v-if="haveExtraData">
        <ion-list>
          <ion-list-header lines="">
            <ion-label class="text-lg uppercase">Traits</ion-label>
          </ion-list-header>

          <ion-item v-for="(value, key) in nft.postExtraData" :key="key">
            <ion-label class="">
              <h2>
              {{ key }}
              </h2>
              <p>{{ value }}</p>
            </ion-label>
          </ion-item>
        </ion-list>
      </div>

  </ion-content>
</template>

<script>

import { computed, defineComponent } from "vue";
import {
    modalController,

    IonAvatar, 
    IonChip, 
    IonHeader,
    IonToolbar,
    IonButton,
    IonButtons,
    IonContent,
    IonIcon,
    IonImg,
    IonItem,
    IonLabel, 
    IonList, 
    IonListHeader, 
    IonText, 
    IonTitle, 

    IonGrid, IonRow, IonCol,

} from "@ionic/vue";
import { Utils } from "../services/utils";

export default defineComponent({

  props: {
    title: { type: String, default: "Modal" },
    nft: null
  },

  setup(props) {
    const utils = new Utils();

    const closeModal = async () => {
        await modalController.dismiss(null, 'cancel');
    };

    const haveImage = computed(function () {
        return props.nft.imageUrl;
    });

    const haveVideo = computed(function () {
        console.log('NFT VIDEO:  ', props.nft);
        return props.nft.videoUrl;
    });

    const haveExtraData = computed(function () {
      if (props.nft.postExtraData) {
        const keys = Object.keys(props.nft.postExtraData);
        return keys.length;
      }
      return false;
    });

    const getUrl = computed(function () {
      return "https://diamondapp.com/nft/"+props.nft.postHash;
    });


    return {
        utils: utils,
        haveImage: haveImage,
        haveVideo: haveVideo,
        haveExtraData: haveExtraData,
        closeModal,
        getUrl: getUrl,
    };
  },

  components: { 
    IonAvatar, 
    IonButton, 
    IonButtons, 
    IonChip, 
    IonContent, 
    IonHeader, 
    IonIcon,
    IonImg,
    IonItem,
    IonLabel, 
    IonList, 
    IonListHeader, 
    IonText, 
    IonTitle, 
    IonToolbar,

    IonGrid, IonRow, IonCol,

  }
});
</script>
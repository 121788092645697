import axios from "axios";

export class ApiService {
    static init(baseURL: string, apiToken: string) {
        axios.defaults.baseURL = baseURL;
        axios.defaults.headers.common['Authorization'] = 'Bearer '+apiToken;
    }

    static async getExchangeRates() {
        let error = null;
        let data = null;
        try {
            const response = await axios.get('deso/exchange-rates');
            if (response.status == 200) {
                data = response.data;
            } else {
                throw new Error(`${response.status}`);
            }
        } catch (err) {
            error = err;
        }

        return {
            data: data,
            error: error
        };
    }

    static async getProfiles(query: string) {
        const body = {
            "prefix": query, 
        }

        let error = null;
        let data = null;
        try {
            const response = await axios.post('deso/profiles', body);
            if (response.status == 200) {
                data = response.data.ProfilesFound;
            } else {
                throw new Error(`${response.status}`);
            }
        } catch (err) {
            error = err;
        }

        return {
            data: data,
            error: error
        };

    }

    static async getPendingNftsForUser(publicKey: string) {
        const body = {
            "publicKey": publicKey,
        };

        let error = null;
        let data = null;
        try {
            const response = await axios.post('deso/nfts/pending', body);
            if (response.status == 200) {
                data = response.data.NFTsMap;
            } else {
                throw new Error(`${response.status}`);
            }
        } catch (err) {
            error = err;
        }

        return {
            data: data,
            error: error
        };
    }

    static async getNotificationsForUser(publicKey: string) {
        const body = {};

        let error = null;
        let data = null;
        try {
            const response = await axios.post('deso/profile/'+publicKey+'/notifications', body);
            if (response.status == 200) {
                data = response.data;
            } else {
                throw new Error(`${response.status}`);
            }
        } catch (err) {
            error = err;
        }

        return {
            data: data,
            error: error
        };
    }

    static async getNftsForUser(publicKey: string) {
        const body = {
            "publicKey": publicKey,
        };

        let error = null;
        let data = null;
        try {
            const response = await axios.post('deso/nfts', body);
            if (response.status == 200) {
                data = response.data.NFTsMap;
            } else {
                throw new Error(`${response.status}`);
            }
        } catch (err) {
            error = err;
        }

        return {
            data: data,
            error: error
        };
    }

    static async getLicensed(profiles: any) {
        const body = {
            "profiles": profiles,
        };

        let error = null;
        let data = null;
        try {
            const response = await axios.post('deso/licensed', body);
            if (response.status == 200) {
                data = response.data.data;
            } else {
                throw new Error(`${response.status}`);
            }
        } catch (err) {
            error = err;
        }

        return {
            data: data,
            error: error
        };
    }

}

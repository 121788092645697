<template>
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="end">
        <ion-button @click="closeModal">Close</ion-button>
      </ion-buttons>

      <ion-title>{{ title }}</ion-title>

    </ion-toolbar>
  </ion-header>
  <ion-content>
        <div class="collection-header">
          <div class="collection-header__main">
            <ion-avatar class="avatar">
                <img
                  :title="collection.username"
                  :src="utils.publicKeyImageUrl(collection.publicKey)"
                />
            </ion-avatar>
              <p  class="avatar">
                {{ collection.username }}
              </p>

          </div>
        </div>

        <p class="ion-padding" style="text-align: center;">
          {{ collection.description }}
        </p>

      <div class="ion-padding-start ion-padding-end">
        <collection-stats class="ion-padding" :collection="collection" />
      </div>

      <ion-item class="">
        <h2>Your NFTs</h2>
      </ion-item>
        
        <nft-grid :nfts="collectionNfts" :showHeader="false"></nft-grid>
  </ion-content>
</template>

<script>
import { ref, computed, defineComponent } from "vue";
import {
    modalController,

    IonAvatar,
    IonButton,
    IonButtons,
    // IonCard,
    // IonCardHeader,
    IonContent,
    IonHeader,
    // IonInput,
    // IonLabel,
    IonTitle,
    IonToolbar,

    // IonList,
    IonItem,
    // IonText,

} from "@ionic/vue";

import CollectionStats from "@/components/CollectionStats.vue";
import NftGrid from "../components/NftGrid.vue";
import { Utils } from "../services/utils";
import { useStore } from "vuex";

export default defineComponent({

  props: {
    title: { type: String, default: "Modal" },
    collection: null
  },

  setup(props) {
    const utils = new Utils;

    const store = useStore();
    const loading = ref(true);

    store.dispatch("loadAccounts").then(function() {
      store.dispatch("loadNfts").then(function() {
        loading.value = false;
      });
    }) 

    const closeModal = async () => {
        await modalController.dismiss(null, 'cancel');
    };

    const nfts = computed(function () {
      return store.getters.nfts;
    });

    const collectionNfts = computed(function () {
      const collectionNfts = [];

      if (nfts.value) {
        const collectionNames = [];

        const map = new Map();
        for (const element of nfts.value) {
          if (element.collection && element.collection.username) {
            if (!collectionNames.includes(element.collection.username)) {
              // map[element.collection.publicKey] = element.collection;
              map.set(element.collection.publicKey, element.collection);
              collectionNames.push(element.collection.username);
              collectionNfts[element.collection.publicKey] = [];
              collectionNfts[element.collection.publicKey].push(element);
            } else {
              collectionNfts[element.collection.publicKey].push(element);
            }
          }
        }
      }
      if (collectionNfts[props.collection.publicKey] !== undefined) {
        return collectionNfts[props.collection.publicKey];
      }

      return null;
    });

    return {
        nfts: nfts,
        utils: utils,
        collectionNfts: collectionNfts,

        closeModal,
    };
  },

  components: { 
    IonAvatar,
    IonButton, 
    IonButtons, 
    IonContent, 
    IonHeader, 
    IonTitle, 
    IonToolbar,
    IonItem,
    
    NftGrid,
    CollectionStats,
  },
});
</script>

import { createStore } from 'vuex'
import { Storage } from '@ionic/storage';
import { ApiService } from '../services/api';

export default createStore({
  state: {
    account:{username:'',publicKey:'', type:''},
    accounts: [{}],
    pendingNfts: [],
    notifications: [],
    nfts: [],
    exchangeRates: [],
    interval: 0
  },
  mutations: {
    saveExchangeRates (state, exchangeRates) {
      state.exchangeRates = exchangeRates
    },
    saveNfts (state, nfts) {
      state.nfts = nfts
    },
    savePendingNfts (state, nfts) {
      state.pendingNfts = nfts
    },
    saveNotifications (state, notifications) {
      state.notifications = notifications
    },
    saveAccounts (state, accounts) {
      state.accounts = accounts
    },
    async addAccount (state, payload) {
      state.accounts.unshift(payload);

      const localStorage = new Storage();
      await localStorage.create();

      await localStorage.set('accounts', JSON.stringify(state.accounts));
    },

    async removeAccount (state, index) {

      const localStorage = new Storage();
      await localStorage.create();

      state.accounts.splice(index, 1)

      await localStorage.set('accounts', JSON.stringify(state.accounts));
    },

    async setTimeInterval(state) {
      state.interval = setInterval(() => {
        console.log('setTimeInterval called');
      }, 1000);
    },
    async clearTimeInterval(state) {
      clearInterval(state.interval);
    }
  
  },
  actions: {
    addAccount(context, payload) {
      context.commit('addAccount', payload);
      setTimeout(() => {
        context.dispatch('loadNfts');
        context.dispatch('loadPendingNfts');
      }, 1000)    
    },

    removeAccount(context, index) {
      context.commit('removeAccount', index);
      setTimeout(() => {
        context.dispatch('loadNfts');
        context.dispatch('loadPendingNfts');
      }, 1000)    
    },

    async loadExchangeRates({ commit }) {
      const rates = await ApiService.getExchangeRates();
      commit('saveExchangeRates', rates.data);
    },

    async loadAccounts({ commit }) {
      const localStorage = new Storage();
      await localStorage.create();
      
      const storedAccounts = await localStorage.get('accounts');
      
      let accountList = [];
      if (storedAccounts) {
        accountList = JSON.parse(storedAccounts);
      }

      if (accountList) {
        const result = accountList.map((a: { publicKey: any }) => a.publicKey);
        const licensed = await ApiService.getLicensed(result);

        for (const a of accountList) {
          a.licensed = licensed.data[a.publicKey];
        }
      }

      commit('saveAccounts', accountList);
    },

    async loadNotifications({ commit }) {

      try {
        const accounts = this.getters.accounts;

        const notificationList: any[] = [];
        const licensedAccounts = accounts.filter( (acc: any) => {
          return acc.licensed == true;
        })
        for (const account of licensedAccounts) {
          const response = await ApiService.getNotificationsForUser(account.publicKey);
          
          const posts = response.data['hashes'] ? response.data['hashes'] : [];
          const profiles = response.data['keys'] ? response.data['keys'] : [];

          if (response.data['transactions']) {
            for (const key in response.data['transactions']) {
              const notification = response.data['transactions'][key];

              if (notification['NFTPostHashHex']) {
                const postObject = posts[notification['NFTPostHashHex']];

                if (postObject) {
                  let nft = {};
                  let imageUrl = null;
                  if (postObject.ImageURLs && postObject.ImageURLs[0]) {
                      imageUrl = postObject.ImageURLs[0];
                  }
                  nft = {
                      id: postObject.PostHashHex,
                      //+'-'+nftObject.SerialNumber,
                      postHash: postObject.PostHashHex,
                      imageUrl: imageUrl,
                      body: postObject.Body,
                      confirmationBlock: postObject.ConfirmationBlockHeight,
                      timestampNanos: postObject.TimestampNanos,
                      // paidNanos: nftObject.LastAcceptedBidAmountNanos,
                      // serialNumber: nftObject.SerialNumber,
                      // isForSale: nftObject.IsForSale,
                      // minBidAmountNanos: nftObject.MinBidAmountNanos,
                      // HighestBidAmountNanos: nftObject.HighestBidAmountNanos,
                      collection: {
                          username: postObject.ProfileEntryResponse.Username,
                          publicKey: postObject.ProfileEntryResponse.PublicKeyBase58Check,
                          description: postObject.ProfileEntryResponse.Description,
                      },
                      account: account,
      
                      likeCount: postObject.LikeCount,
                      commentCount: postObject.CommentCount,
                      diamondCount: postObject.DiamondCount,
                      quoteRepostCount: postObject.QuoteRepostCount,
                      repostCount: postObject.RepostCount,
      
                      postExtraData: postObject.PostExtraData,
                      nftRoyaltyToCoinBasisPoints: postObject.NFTRoyaltyToCoinBasisPoints,
                      nftRoyaltyToCreatorBasisPoints: postObject.NFTRoyaltyToCreatorBasisPoints,
      
                  };

                  notification['Post'] = nft;
                }
              }

              if (notification['NFTOwnerPublicKeyBase58Check']) {
                notification['Owner'] = profiles[notification['NFTOwnerPublicKeyBase58Check']];
              }

              if (notification['ReceiverPublicKey']) {
                notification['Receiver'] = profiles[notification['ReceiverPublicKey']];
              }

              if (notification['TransactorPublicKeyBase58Check']) {
                notification['Transactor'] = profiles[notification['TransactorPublicKeyBase58Check']];
              }

              // let imageUrl = null;
              // if (notification.post.ImageURLs && notification.post.ImageURLs[0]) {
              //   imageUrl = notification.post.ImageURLs[0];
              // } 
              // notification.imageUrl = imageUrl;
              // console.log(notificationList);
              notificationList.push(notification);
            }
          }
        }
        commit('saveNotifications', notificationList);
      }
      catch (error) {
        console.log(error);
      }
    },

    async loadPendingNfts({ commit }) {

      try {
        const accounts = this.getters.accounts;

        const nftList = [];
        const licensedAccounts = accounts.filter( (acc: any) => {
          return acc.licensed == true;
        })
        for (const account of licensedAccounts) {
          const response = await ApiService.getPendingNftsForUser(account.publicKey);
  
          for (const key in response.data) {
            const obj = response.data[key];
            const nftObject = obj.NFTEntryResponses[0];
            const postObject = obj.PostEntryResponse;
            let nft = {};
            let imageUrl = null;
            if (postObject.ImageURLs && postObject.ImageURLs[0]) {
                imageUrl = postObject.ImageURLs[0];
            }
            nft = {
                id: postObject.PostHashHex+'-'+nftObject.SerialNumber,
                postHash: postObject.PostHashHex,
                imageUrl: imageUrl,
                body: postObject.Body,
                confirmationBlock: postObject.ConfirmationBlockHeight,
                timestampNanos: postObject.TimestampNanos,
                paidNanos: nftObject.LastAcceptedBidAmountNanos,
                serialNumber: nftObject.SerialNumber,
                isForSale: nftObject.IsForSale,
                minBidAmountNanos: nftObject.MinBidAmountNanos,
                HighestBidAmountNanos: nftObject.HighestBidAmountNanos,
                collection: {
                    username: postObject.ProfileEntryResponse.Username,
                    publicKey: postObject.ProfileEntryResponse.PublicKeyBase58Check,
                    description: postObject.ProfileEntryResponse.Description,
                },
                account: account,

                likeCount: postObject.LikeCount,
                commentCount: postObject.CommentCount,
                diamondCount: postObject.DiamondCount,
                quoteRepostCount: postObject.QuoteRepostCount,
                repostCount: postObject.RepostCount,

                postExtraData: postObject.PostExtraData,
                nftRoyaltyToCoinBasisPoints: postObject.NFTRoyaltyToCoinBasisPoints,
                nftRoyaltyToCreatorBasisPoints: postObject.NFTRoyaltyToCreatorBasisPoints,

            };
            nftList.push(nft);
          }
        }
        commit('savePendingNfts', nftList)
      }
      catch (error) {
        console.log(error);
      }
    },

    async loadNfts({ commit }) {
      try {
        const accounts = this.getters.accounts;

        const nftList = [];
        const licensedAccounts = accounts.filter( (acc: any) => {
          return acc.licensed == true;
        })
        for (const account of licensedAccounts) {
          const response = await ApiService.getNftsForUser(account.publicKey);
  
          for (const key in response.data) {
            const obj = response.data[key];
            const nftObject = obj.NFTEntryResponses[0];
            const postObject = obj.PostEntryResponse;
            let nft = {};
            let imageUrl = null;
            if (postObject.ImageURLs && postObject.ImageURLs[0]) {
                imageUrl = postObject.ImageURLs[0];
            }
            let videoUrl = null;
            if (postObject.VideoURLs && postObject.VideoURLs[0]) {
              videoUrl = postObject.VideoURLs[0];
            }
            nft = {
                id: postObject.PostHashHex+'-'+nftObject.SerialNumber,
                postHash: postObject.PostHashHex,
                imageUrl: imageUrl,
                videoUrl: videoUrl,
                body: postObject.Body,
                confirmationBlock: postObject.ConfirmationBlockHeight,
                timestampNanos: postObject.TimestampNanos,
                paidNanos: nftObject.LastAcceptedBidAmountNanos,
                serialNumber: nftObject.SerialNumber,
                isForSale: nftObject.IsForSale,
                minBidAmountNanos: nftObject.MinBidAmountNanos,
                HighestBidAmountNanos: nftObject.HighestBidAmountNanos,
                collection: {
                    username: postObject.ProfileEntryResponse.Username,
                    publicKey: postObject.ProfileEntryResponse.PublicKeyBase58Check,
                    description: postObject.ProfileEntryResponse.Description,
                },
                account: account,

                likeCount: postObject.LikeCount,
                commentCount: postObject.CommentCount,
                diamondCount: postObject.DiamondCount,
                quoteRepostCount: postObject.QuoteRepostCount,
                repostCount: postObject.RepostCount,

                postExtraData: postObject.PostExtraData,
                nftRoyaltyToCoinBasisPoints: postObject.NFTRoyaltyToCoinBasisPoints,
                nftRoyaltyToCreatorBasisPoints: postObject.NFTRoyaltyToCreatorBasisPoints,

            };
            nftList.push(nft);
          }
        }
        commit('saveNfts', nftList)
      }
      catch (error) {
        console.log(error);
      }
    }
  },
  modules: {
  },
  getters: {
    nfts: state => {
      return state.nfts;
    },
    pendingNfts: state => {
      return state.pendingNfts;
    },
    accounts: state => {
      return state.accounts;
    },
    exchangeRates: state => {
      return state.exchangeRates;
    },
    notifications: state => {
      return state.notifications;
    },

  }
})
